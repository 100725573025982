<template>
  <div class="manual-edit-root">
    <SportSidebar
      title="Sports"
      :disabled="isDataLoading"
      :selected-sport-id="selectedSportId"
      @update:selectedSportId="updateSelectedSport"
    />
    <div
      :class="[
        'manual-edit-content',
        { 'sidebar-collapsed': sidebarCollapsed },
      ]"
    >
      <header class="manual-edit-header">
        <Heading>
          {{ headerTitle }}
        </Heading>
        <div class="filter-root">
          <Dropdown
            v-if="showSearchPlayerBy"
            class="source-filter"
            :label="selectedSearchPlayerByLabel"
          >
            <DropdownItem
              clickable
              @click="onSearchPlayerByChange('player-name')"
            >
              Player name
            </DropdownItem>
            <DropdownItem
              clickable
              @click="onSearchPlayerByChange('player-id')"
            >
              Player ID
            </DropdownItem>
            <DropdownItem
              clickable
              @click="onSearchPlayerByChange('player-index')"
            >
              Player index
            </DropdownItem>
          </Dropdown>
          <TextInput
            class="team-search-input"
            :model-value="searchValue"
            @update:model-value="updateSearchValue"
            icon="search"
            :placeholder="searchLabel"
            small
          />
          <Dropdown
            class="source-filter"
            :label="selectedFeed || 'Filter by source'"
          >
            <DropdownItem
              v-for="(feed, index) in allFeeds"
              :key="index"
              clickable
              @click="onFeedItemClicked(feed.feed)"
            >
              {{ feed.feed }}
            </DropdownItem>
            <DropdownItem
              clickable
              @click="onFiltersClear"
            >
              Clear filter
            </DropdownItem>
          </Dropdown>
          <Button
            class="create-new-record-btn"
            variant="primary"
            icon="plus"
            @click="createNewRecord"
          >
            {{ createNewRecordBtnLabel }}
          </Button>
        </div>
      </header>
      <div class="manual-edit-body">
        <router-view v-if="!isDataLoading" />
        <Spinner v-else />
      </div>
    </div>
  </div>
</template>

<script>
import {
  computed, onMounted, watch,
} from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { debounce, cloneDeep } from 'lodash';
import Heading from '@/components/common/Heading';
import SportSidebar from '@/components/common/SportSidebar';
import Spinner from '@/components/common/Spinner';
import TextInput from '@/components/common/TextInput';
import Dropdown from '@/components/common/Dropdown';
import DropdownItem from '@/components/common/DropdownItem';
import Button from '@/components/common/Button';
import sportIds from '@/services/helpers/sports';
import { teamsTableColumns, playersTableColumns } from './table-configuration';

const {
  FOOTBALL_ID,
} = sportIds;

export default {
  components: {
    Heading,
    SportSidebar,
    Spinner,
    TextInput,
    Dropdown,
    DropdownItem,
    Button,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const isDataLoading = computed(() => store.getters.manualEditDataLoading);
    const selectedSportId = computed(() => route.query.sport ?? store.getters.manualEditSelectedSport);
    const searchValue = computed(() => store.getters.manualEditSearch?.value || '');
    const selectedFeed = computed(() => store.getters.manualEditSelectedFeed);
    const selectedSearchPlayerBy = computed(() => store.getters.manualEditSearch?.searchBy || 'player-name');
    const selectedSearchPlayerByLabel = computed(() => {
      if (selectedSearchPlayerBy.value === 'player-id') {
        return 'Player ID';
      }
      if (selectedSearchPlayerBy.value === 'player-index') {
        return 'Player index';
      }
      return 'Player name';
    });
    const showSearchPlayerBy = computed(() => route.name === 'playerManualEdit');
    const searchLabel = computed(() => {
      if (route.name === 'teamManualEdit') {
        return 'Search team name';
      }
      if (route.name === 'playerManualEdit') {
        return 'Search player';
      }
      return 'Search';
    });
    const headerTitle = computed(() => {
      if (route.name === 'teamManualEdit') {
        return 'Manual edit - Teams';
      }
      if (route.name === 'playerManualEdit') {
        return 'Manual edit - Players';
      }
      return 'Manual edit';
    });
    const allFeeds = computed(() => store.getters.allFeeds);
    const clearFeedFilterDisabled = computed(() => !selectedFeed.value);
    const createNewRecordBtnLabel = computed(() => {
      if (route.name === 'teamManualEdit') {
        return 'Create new team';
      } if (route.name === 'playerManualEdit') {
        return 'Create new player';
      }
      return '';
    });
    const sidebarCollapsed = computed(() => !store.getters.sidebarExpanded);

    const loadData = () => {
      if (route.name === 'teamManualEdit') {
        store.dispatch('loadManualEditTeams');
      } else if (route.name === 'playerManualEdit') {
        store.dispatch('loadManualEditPlayers');
      }
    };
    const updateSearchValue = debounce((val) => {
      store.dispatch('updateManualEditSearch', {
        value: val,
        searchBy: selectedSearchPlayerBy.value,
      }).then(() => loadData());
    }, 300);
    const onFeedItemClicked = (feed) => {
      store.dispatch('updateManualEditSelectedFeed', feed).then(() => loadData());
    };

    const updateSelectedSport = (sportId) => {
      store.dispatch('updateManualEditSelectedSport', sportId).then(() => loadData());
      const query = cloneDeep(route.query);
      delete query.teamId;
      router.push({
        name: route.name,
        query: {
          ...query,
          sport: sportId,
        },
      });
    };

    const setTableColumns = () => {
      if (route.name === 'teamManualEdit') {
        store.dispatch('updateManualEditTableColumns', teamsTableColumns());
      } else if (route.name === 'playerManualEdit') {
        store.dispatch('updateManualEditTableColumns', playersTableColumns());
      }
    };

    const clearFiltersAndReloadData = () => {
      store.dispatch('updateManualEditSelectedFeed', null).then(() => loadData());
    };

    const onFiltersClear = () => {
      if (clearFeedFilterDisabled.value) return;
      clearFiltersAndReloadData();
    };

    const createNewRecord = () => store.dispatch('updateManualEditIsCreateNewRecordActive', true);

    const onSearchPlayerByChange = (searchBy) => {
      store.dispatch('updateManualEditSearch', {
        value: searchValue.value,
        searchBy,
      }).then(() => {
        if (searchValue.value) loadData();
      });
    };

    onMounted(() => {
      setTableColumns();
      // Initial sport is set to AF
      updateSelectedSport(route.query.sport || FOOTBALL_ID);
    });

    watch(
      () => [
        store.getters.currentPage,
        store.getters.rowsPerPage,
      ],
      loadData,
    );

    watch(() => route.name, () => {
      setTableColumns();
      clearFiltersAndReloadData();
    });

    return {
      isDataLoading,
      selectedSportId,
      searchValue,
      selectedFeed,
      searchLabel,
      headerTitle,
      updateSearchValue,
      allFeeds,
      createNewRecordBtnLabel,
      sidebarCollapsed,
      selectedSearchPlayerBy,
      selectedSearchPlayerByLabel,
      showSearchPlayerBy,
      updateSelectedSport,
      onFeedItemClicked,
      createNewRecord,
      onFiltersClear,
      onSearchPlayerByChange,
    };
  },
};
</script>

<style lang="scss">
  .manual-edit-root {
    .manual-edit-content {
      width: calc(100% - #{$sidebarWidth});
      height: 100%;

      &.sidebar-collapsed {
        width: calc(100% - #{$sidebarWidthCollapsed});
      }

      .manual-edit-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px;
        .filter-root {
          display: flex;
          align-items: center;
          gap: 8px;
          .source-filter {
            .dropdown-item {
              display: flex;
              align-items: center;
              justify-content: space-between;
              box-shadow: none;

              &:last-child {
                border-top: 1px solid $gray400;
              }
            }
          }
        }
      }
      .manual-edit-body {
        .table {
          .table-row {
            cursor: pointer;
          }
        }
      }
      .create-new-record-btn {
        .icon {
          svg {
            path {
              stroke: $white;
            }
          }
        }
      }
    }
  }
</style>
