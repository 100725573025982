export const teamsTableColumns = () => ({
  name: {
    label: 'Team name',
    visible: true,
    order: 0,
    filterable: false,
    sortable: false,
  },
  source: {
    label: 'Source',
    visible: true,
    order: 1,
    filterable: false,
    sortable: false,
  },
  shortName: {
    label: 'Short name',
    visible: true,
    order: 2,
    filterable: false,
    sortable: false,
  },
  action: {
    label: '',
    visible: true,
    order: 3,
    filterable: false,
    sortable: false,
    maxWidth: 50,
  },
});

export const playersTableColumns = () => ({
  playerId: {
    label: 'Player id',
    visible: true,
    order: 0,
    filterable: false,
    sortable: false,
    copyAvailable: true,
  },
  playerIndex: {
    label: 'Player index',
    visible: true,
    order: 1,
    filterable: false,
    sortable: false,
    copyAvailable: true,
  },
  name: {
    label: 'Player name',
    visible: true,
    order: 2,
    filterable: false,
    sortable: false,
  },
  teamName: {
    label: 'Team name',
    visible: true,
    order: 3,
    filterable: false,
    sortable: false,
  },
  teamId: {
    label: 'Team id',
    visible: true,
    order: 4,
    filterable: false,
    sortable: false,
    copyAvailable: true,
  },
  source: {
    label: 'Source',
    visible: true,
    order: 5,
    filterable: false,
    sortable: false,
  },
  action: {
    label: '',
    visible: true,
    order: 6,
    filterable: false,
    sortable: false,
    maxWidth: 50,
  },
});
